function processAngle(angle) {
  if (typeof angle === "number") {
    return `${angle}deg`
  } else if (typeof angle === "string") {
    return angle
  } else {
    return "90deg"
  }
}

export function linearGradient(angle, gradient) {
  return `linear-gradient(${processAngle(angle)}, ${gradient})`
}
