import { linearGradient } from "@utils/gradient"
import styled from "styled-components"
import PropTypes from "prop-types"

const getGradient = ({ gradient, variant, theme }) => {
  if (gradient) {
    return gradient
  }

  switch (variant) {
    case "powermetrics":
      return linearGradient(90, theme.gradient.purplePink)
    case "homepage":
      return linearGradient(90, theme.gradient.deepPurplePink)
    case "klips":
      return linearGradient(90, theme.gradient.klips)
    default:
      return null
  }
}

const GradientText = styled.span`
  display: inline-block;
  background: ${getGradient};
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
`

GradientText.propTypes = {
  gradient: PropTypes.string,
  variant: PropTypes.oneOf(["powermetrics", "homepage"]),
}

export default GradientText
