import React from "react"
import Layout from "@components/layout"
import Container from "@components/layout/container"
import Div from "@components/elements/div"
import Paragraph from "@components/elements/paragraph"
import Anchor from "@components/elements/anchor"
import Heading from "@components/elements/heading"
import GradientText from "@components/common/gradient-text"

const NotFoundPage = () => (
  <Layout
    fullWidth
    title={`404: Not Found`}
    description={`The page you’re looking for is missing.`}
    marginless
  >
    <Container text>
      <Div margin="8rem auto 0" center>
        <Heading
          fontSize="16rem"
          mobileScale="0.5"
          color="white"
          style={{ minWidth: "max-content" }}
        >
          <GradientText
            gradient={"linear-gradient(90deg, #7B6CCC 0%, #886BC1 100%)"}
            style={{
              filter: "blur(2px)",
              transform: "rotate(-15deg)",
              marginRight: "1rem",
            }}
          >
            4
          </GradientText>
          <GradientText
            gradient={"linear-gradient(90deg, #956AB6 0%, #A26AAA 100%)"}
            style={{
              filter: "blur(4px)",
              transform: "rotate(5deg) scale(1.1)",
              marginRight: "1rem",
            }}
          >
            0
          </GradientText>
          <GradientText
            gradient={"linear-gradient(90deg, #AF699F 0%, #BC6894 100%)"}
            style={{
              filter: "blur(8px)",
              transform: "rotate(20deg)",
            }}
          >
            4
          </GradientText>
        </Heading>
      </Div>
      <Paragraph center margin="0 auto 6rem">
        Expecting something here?
        <br />
        <Anchor to={`/about/contact-us`}>Contact us</Anchor>
      </Paragraph>
    </Container>
  </Layout>
)

export default NotFoundPage
